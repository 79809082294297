import React from "react";
import * as yup from "yup";
import { useClient } from "jsonapi-react";
import { nanoid } from "nanoid";

import { FileProduct, MountPoint } from "../../api/types";
import LazyAdmin from "../../components/lazy-admin/lazy-admin";

export default function FileProductPage() {
  const client = useClient();
  return (
    <>
      <LazyAdmin<FileProduct, MountPoint>
        resource="datatable-collections"
        title="File Products"
        defaults={{
          sortBy: "updatedAt",
          sortOrder: "desc",
          searchable: "value",
          pageSize: 5
        }}
        submissionTransformer={c => {
          const copy = { ...c };
          if (copy.mountPoints) {
            copy.mountPointIds = copy.mountPoints.map((v: any) => v.value);
          } else {
            copy.mountPointIds = [];
          }
          if (!copy.code) {
            copy.code = `F_${nanoid(3)}`;
          }
          delete copy.mountPoints;
          return copy;
        }}
        filter={{
          productType: "FILE"
        }}
        include={["mount-points"]}
        tableColumns={[
          {
            id: "name",
            title: "Name",
            sortable: true,
            editable: {
              type: "string",
              defaultValue: "",
              schema: yup.string().required("Name is required").default("")
            }
          },
          {
            id: "code",
            title: "Code",
            sortable: true,
            editable: {
              type: "hidden"
            }
          },
          {
            id: "sftpInfotmation",
            title: "SFTP Information",
            leftAlign: true,
            tableValueRenderer: (v: { path: string; target: string }[]) => {
              return (
                <>
                  {v.map(vv => (
                    <div key={vv.path}>
                      <span>
                        {vv.path}
                        {">"}
                        {vv.target}
                      </span>
                    </div>
                  ))}
                </>
              );
            }
          },
          {
            id: "mountPoints",
            relationship: {
              type: "many",
              labelAccess: "path",
              valueAccess: "id"
            },
            editable: {
              type: "reference",
              title: "Mount Points",
              multiple: true,
              defaultOptions: true,
              loadOptions: (inputValue: string, callback: any) => {
                client
                  .fetch([
                    "mount-points",
                    {
                      filter: {
                        ...(inputValue && { value: inputValue }),
                        unassigned: true
                      }
                    }
                  ])
                  .then((result: any) => {
                    callback(
                      result.data?.map((v: any) => ({
                        label: v.path,
                        value: v.id
                      }))
                    );
                  });
              }
            }
          },
          {
            id: "productType",
            editable: {
              type: "hidden",
              defaultValue: "FILE"
            }
          },
          {
            id: "createdAt",
            title: "Created At",
            sortable: true,
            date: true
          },
          {
            id: "updatedAt",
            title: "Updated At",
            sortable: true,
            date: true
          }
        ]}
      />
    </>
  );
}
