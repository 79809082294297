import React, { useCallback, useContext, useState } from "react";
import { IResult, useMutation, useQuery } from "jsonapi-react";
import {
  FlexGrid,
  FormSelect,
  FormField,
  Button,
  Header,
  Switch,
  Box
} from "@nef/core";
import styled from "styled-components";
import {
  Controller,
  FieldArrayWithId,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { generatePath, useHistory } from "react-router-dom";

import { ConfirmationModal } from "../modals/ConfirmationModal";
import {
  DatatableColumn,
  DatatableSchema,
  Vendor,
  FilterableColumnValues,
  AccumulatedColumnStats,
  ColumnTypeStats,
  VersionData
} from "../../api/types";
import { Step } from "../wizard";
import DatatableContext from "../../contexts/datatable";
import { extractErrors } from "../../utils/react-hook-form-utils";
import createDatatableBody, {
  calculateDatatableKeysAndFilters
} from "../../api/normalizers/datatable";
import Toast from "../Toast";
import LabelValueWithHint from "../common/LabelValueWithHint";
import globalStyles from "../../styles/common.module.scss";
import { PATHS } from "../../routes";
import { fecthDatatableVersions } from "../../api/api";

import DatatableSchemaForm from "./DatatableSchemaForm";
import {
  DATATABLE_CODE_INFO,
  DATATABLE_DEFAULT_VERSION_TOGGLE_INFO,
  DATA_LOCATION_FILE_PATTERN_INFO,
  DATA_LOCATION_REMOTE_PATH_INFO
} from "./hints";
import datatableSchema from "./schemas";
import DatatatableColumnFilters from "./components/DatatableColumnFilters";
import AutoSchemaPopulateButton from "./components/AutoSchemaPopulateButton";
import styles from "./DatatableSchemaStep.module.scss";
import { METHOD_OPTIONS } from "./dropdown-options";

const MetadataGrid = styled(FlexGrid)`
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
`;

interface onChangeParam {
  [key: string]: any;
}

interface DatatableSchemaStepProps {
  createMode: boolean;
  setBackend?: (_: string) => void;
}

const AddColumnRow = styled(FlexGrid.Row)`
  border-top: 1px solid #d1d1d1;
  padding-top: 15px;
  justify-content: space-between;
`;

const DatatableSchemaStep = ({
  createMode,
  setBackend
}: DatatableSchemaStepProps) => {
  const history = useHistory();
  const [currentFilter, setCurrentFilter] =
    useState<FilterableColumnValues>(null);
  const [versions, setVersions] = useState<VersionData[]>([]);
  let datatable: DatatableSchema;

  const DEFAULT_CRON_VALUE = "*/15 * * * *";
  const { datatable: datatableFromContext, setDatatable } =
    useContext(DatatableContext);

  const [redirectVendorId, setRedirectVendorId] = useState<number | undefined>(
    undefined
  );

  if (!createMode) {
    datatable = datatableFromContext;
  } else {
    datatable = {
      name: "",
      code: "",
      vendorCode: "",
      columns: [],
      version: {
        code: "0",
        description: "",
        default: true
      },
      source: {
        cronTime: DEFAULT_CRON_VALUE,
        filePattern: "",
        host: "",
        type: "s3"
      },
      creator: { firstName: "", lastName: "", email: "" },
      updatedAt: new Date(),
      createdAt: new Date(),
      backend: "huron",
      partitions: []
    };
  }

  const { data: vendors } = useQuery<Vendor[]>(["vendors"]);

  const [createDatatable] = useMutation<DatatableSchema>("datatable_schema");
  const [editDatatable] = useMutation<DatatableSchema>(
    [
      "datatable_schema",
      `${datatable.vendorCode}/${datatable.code}/${datatable.version.code}`
    ],
    {
      method: "PUT"
    }
  );

  const [columnReadyForDeletion, setColumnReadyForDeletion] =
    useState<FieldArrayWithId<DatatableSchema, "columns", "id"> | null>();

  const vendorSelectionOptions =
    vendors?.map(v => {
      return { value: v.code, label: v.name };
    }) || [];

  const getAllDatatableVersions = async () => {
    try {
      const { data } = await fecthDatatableVersions(
        datatable.vendorCode,
        datatable.code
      );
      setVersions(
        [
          ...data.relationships.versions.data,
          { id: data.attributes.version.code, type: "versions", default: true }
        ].sort((a, b) => Number(a.id) - Number(b.id))
      );
    } catch (e) {
      toast(
        <Toast
          type="error"
          title="Cannot fecth datatable versions"
          details={[]}
        />
      );
    }
  };

  React.useEffect(() => {
    if (!createMode) getAllDatatableVersions();
  }, [createMode]);

  const versionSelectOptions = {
    options:
      versions.map(version => ({
        value: version.id,
        label: `datatable - version ${version.id}`
      })) || []
  };

  const methods = useForm({
    defaultValues: datatable,
    resolver: yupResolver(datatableSchema)
  });
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty }
  } = methods;

  const backend = useWatch({
    control,
    name: "backend"
  });
  React.useEffect(() => {
    setBackend?.(backend);
  }, [backend]);

  const {
    fields,
    append: appendFieldArrayColumn,
    update: updateFieldArrayColumn,
    remove: removeFieldArrayColumn,
    replace: replaceFieldArrayColumns
  } = useFieldArray({
    control,
    name: "columns"
  });

  const addColumn = () => {
    const newColumn = {
      name: "",
      type: "",
      dateFormat: "",
      precision: null,
      scale: null,
      isNew: true,
      isIndex: false,
      isPrimaryKey: false,
      isPartitioned: false
    };

    appendFieldArrayColumn(newColumn);
  };

  const updateColumn = (index: number, newValues: Partial<DatatableColumn>) => {
    const existingColumn = getValues(`columns.${index}`);
    const newColumn = { ...existingColumn, ...newValues };

    updateFieldArrayColumn(index, newColumn);
  };

  const tryRemoveColumn = (
    column: FieldArrayWithId<DatatableSchema, "columns", "id">
  ) => {
    if (column.isNew) {
      removeColumn(column);
    } else {
      setColumnReadyForDeletion(column);
    }
  };

  const removeColumn = (
    columnToRemove: FieldArrayWithId<DatatableSchema, "columns", "id">
  ) => {
    const removeIndex = fields.findIndex(
      column => column.id === columnToRemove.id
    );
    removeFieldArrayColumn(removeIndex);
    setColumnReadyForDeletion(null);
  };

  const prepareAndFinishDatatable = (newDatatable: DatatableSchema) => {
    const normalizedDatatable: any = createDatatableBody(newDatatable, true);
    return createDatatable(normalizedDatatable);
  };

  const handleS3DatatableResponse = (
    response: IResult<DatatableSchema>,
    finishFlow = false
  ) => {
    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      const formattedErrors = responseErrors
        .map(e => {
          const allMessages: string[] = [];
          if (typeof e.detail === "string") {
            allMessages.push(e.detail);
          }

          if (Array.isArray(e.detail?.defaultFilters)) {
            allMessages.push(...e.detail.defaultFilters);
          }

          return allMessages;
        })
        .flat()
        .map(message => {
          return { message };
        });

      toast(
        <Toast
          type="error"
          title="Cannot create datatable"
          details={formattedErrors}
        />
      );

      return;
    }
    const responseData = response.data as DatatableSchema;

    if (responseData) {
      if (finishFlow) {
        const { vendorId } = responseData;
        setRedirectVendorId(vendorId);
      } else {
        history.replace(
          generatePath(`${PATHS.EDIT_DATATABLE}`, {
            vendorCode: responseData.vendorCode,
            datatableCode: responseData.code,
            versionCode: responseData.version.code
          })
        );
        toast(<Toast type="success" title="Progress is saved" details={[]} />);
      }
    } else {
      toast(
        <Toast
          type="info"
          title="Progress is saved"
          details={[
            {
              message:
                "Upstream response contains errors or is empty, You may still go back to edit datatable info"
            }
          ]}
        />
      );
    }
  };

  const onFinishS3Datatable: SubmitHandler<DatatableSchema> = useCallback(
    async newDatatable => {
      const response = datatableIsPublished
        ? // update published datatable
          await prepareAndUpdateDatatable(newDatatable)
        : // create datatable directly
          await prepareAndFinishDatatable(newDatatable);

      handleS3DatatableResponse(response, true);
    },
    []
  );

  const onSaveDatatable: SubmitHandler<DatatableSchema> = useCallback(
    async newDatatable => {
      if (backend === "huron") {
        if (!isDirty || datatableIsPublished) {
          return;
        }
        const previousCode =
          !createMode && datatable.code !== newDatatable.code
            ? datatable.code
            : null;
        const response = await prepareAndCreateDatatable(
          newDatatable,
          previousCode
        );
        handleS3DatatableResponse(response);
      } else if (backend === "postgres") {
        if (!isDirty) {
          history.push(
            generatePath(`${PATHS.EDIT_DATATABLE}/dataLocation`, {
              vendorCode: newDatatable.vendorCode,
              datatableCode: newDatatable.code,
              versionCode: newDatatable.version.code
            })
          );
          return;
        }
        const previousCode =
          !createMode && datatable.code !== newDatatable.code
            ? datatable.code
            : null;

        const response = datatableIsPublished
          ? await prepareAndUpdateDatatable(newDatatable)
          : await prepareAndCreateDatatable(newDatatable, previousCode);

        handleDatatableResponse(response);
      }
    },
    [isDirty, backend, datatable]
  );

  const prepareAndCreateDatatable = (
    newDatatable: DatatableSchema,
    previousCode: string | null
  ) => {
    const normalizedDatatable: any = createDatatableBody(
      newDatatable,
      false,
      previousCode
    );

    return createDatatable(normalizedDatatable);
  };

  const prepareAndUpdateDatatable = (newDatatable: DatatableSchema) => {
    const { defaultColumns, defaultFilters } =
      calculateDatatableKeysAndFilters(newDatatable);

    const requestBody = {
      default_columns: defaultColumns,
      default_filters: defaultFilters
    };
    return editDatatable(requestBody);
  };

  const updateDatatableVersion = async (defaultValue: boolean) => {
    const requestBody = {
      version: {
        code: datatable.version.code,
        default: defaultValue
      }
    };
    try {
      const response = await editDatatable(requestBody);
      if (response.error || response.errors) {
        const responseErrors = response.error
          ? [response.error]
          : response.errors;

        const formattedErrors = responseErrors?.map(err => ({
          message: err.detail
        }));
        toast(
          <Toast
            type="error"
            title="Cannot update datatable"
            details={formattedErrors || []}
          />
        );
      } else {
        toast(
          <Toast
            type="success"
            title="Datatable updated successfully"
            details={[{ message: "You've set current version as default" }]}
          />
        );
      }
    } catch (error: any) {
      toast(
        <Toast
          type="error"
          title="Error"
          details={[{ message: error.message }]}
        />
      );
    }
  };

  const handleDatatableResponse = (response: IResult<DatatableSchema>) => {
    const { error, errors } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      const formattedErrors = responseErrors
        .map(e => {
          const allMessages: string[] = [];
          if (typeof e.detail === "string") {
            allMessages.push(e.detail);
          }

          if (Array.isArray(e.detail?.defaultFilters)) {
            allMessages.push(...e.detail.defaultFilters);
          }

          return allMessages;
        })
        .flat()
        .map(message => {
          return { message };
        });

      toast(
        <Toast
          type="error"
          title="Cannot create datatable"
          details={formattedErrors}
        />
      );

      return;
    }
    const responseData = response.data as DatatableSchema;

    if (responseData) {
      toast(
        <Toast
          type="success"
          title="Progress is saved"
          details={[{ message: "You may go back to edit datatable info" }]}
        />
      );

      setDatatable(responseData);

      history.push(
        generatePath(`${PATHS.EDIT_DATATABLE}/dataLocation`, {
          vendorCode: responseData.vendorCode,
          datatableCode: responseData.code,
          versionCode: responseData.version.code
        })
      );
    } else {
      toast(
        <Toast
          type="info"
          title="Progress is saved"
          details={[
            {
              message:
                "Upstream response contains errors or is empty, You may still go back to edit datatable info"
            }
          ]}
        />
      );
    }
  };

  const onSaveDatatableError: SubmitErrorHandler<DatatableSchema> = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );

    return false;
  };

  const datatableIsPublished = datatable.version?.code === "FINAL";

  const onFileProcessed = (
    accumulated: AccumulatedColumnStats,
    fileType: String
  ) => {
    const newColumns: DatatableColumn[] = [];
    const messages: string[] = [
      `New datatable columns have been successfully generated from the ${fileType} file`
    ];
    Object.keys(accumulated).forEach((key, columnIndex) => {
      const columnEntry = accumulated[key as unknown as number];

      const typeList = Object.keys(columnEntry.types);
      if (typeList.length === 1) {
        const newColumn = createColumnFromColumnStats(
          columnEntry.header,
          columnEntry.types[typeList[0]]
        );
        newColumns.push(newColumn);
      } else {
        const newColumn = createColumnFromColumnStats(columnEntry.header);
        newColumns.push(newColumn);

        messages.push(
          `No datatype has been set for Column ${
            columnIndex + 1
          } because more than 1 data type has been detected in the CSV file`
        );
      }
    });

    replaceFieldArrayColumns(newColumns);

    const toastType = messages.length === 1 ? "success" : "info";

    const shouldAutoClose = toastType === "success" ? undefined : false;

    toast(
      <Toast
        type={toastType}
        title="Datatable columns generated"
        details={messages.map(message => {
          return { message };
        })}
      />,
      {
        autoClose: shouldAutoClose
      }
    );
  };

  const createColumnFromColumnStats = (
    name: string,
    stats?: ColumnTypeStats
  ) => {
    const newColumn: DatatableColumn = {
      name,
      type: "",
      baseType: "",
      dateFormat: "",
      isNew: true,
      isIndex: false,
      isPrimaryKey: false
    };

    if (!stats) {
      return newColumn;
    }

    const { type } = stats;

    if (type === "date") {
      newColumn.baseType = "date";
      newColumn.dateFormat = "YYYY-MM-DD";
    } else if (type === "string") {
      newColumn.baseType = "string";
    } else if (type === "number") {
      const whole = stats.whole as number;
      const scale = stats.scale as number;
      const isDecimal = scale > 0;
      newColumn.baseType = isDecimal ? "decimal" : "integer";

      if (newColumn.baseType === "decimal") {
        newColumn.precision = whole + scale;
        newColumn.scale = scale;
      }
    }

    return newColumn;
  };

  return (
    <>
      <Step
        name="Datatable Info"
        path="schema"
        canAdvance={true}
        save={handleSubmit(onSaveDatatable, onSaveDatatableError)}
        finish={
          backend === "huron"
            ? handleSubmit(onFinishS3Datatable, onSaveDatatableError)
            : undefined
        }
      >
        <MetadataGrid fluid={true} data-testid="datatableSchemaStep_form">
          <FlexGrid.Row>
            <FlexGrid.Column xs={24} md={12}>
              <Box paddingBottom={4}>
                <Header size={1}>Table Schema</Header>
                <div className={globalStyles.subheader}>
                  This section allows you to define the properties of the data
                  table. You can define the data schema manually or upload a
                  sample data file to auto-populate the schema below. Ensure you
                  are selecting the correct data table version if applicable.
                </div>
              </Box>
            </FlexGrid.Column>
          </FlexGrid.Row>
          <FlexGrid.Row>
            <FlexGrid.Column sm={8}>
              <Controller
                name="vendorCode"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormSelect
                      id="vendorCode"
                      name={name}
                      label="Select the vendor"
                      value={vendorSelectionOptions.find(
                        v => v.value === value
                      )}
                      placeholder="Select a vendor"
                      disabled={!createMode}
                      options={vendorSelectionOptions}
                      onChange={({ value: v }: onChangeParam) => {
                        onChange(v.value);
                      }}
                      classNamePrefix="vendorCode"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
            <FlexGrid.Column sm={8}>
              <Controller
                name="version"
                control={control}
                render={({ field: { value, name } }) => {
                  return (
                    <FormSelect
                      id="version"
                      name={name}
                      label="Select the version"
                      placeholder="Select a version"
                      value={versionSelectOptions.options.find(
                        v => v.value === value.code
                      )}
                      options={versionSelectOptions.options}
                      disabled={createMode}
                      onChange={({ value: v }: onChangeParam) => {
                        setValue(name, v.value, { shouldDirty: false });
                        history.push(
                          generatePath(`${PATHS.EDIT_DATATABLE}`, {
                            vendorCode: datatable.vendorCode,
                            datatableCode: datatable.code,
                            versionCode: v.value
                          })
                        );
                      }}
                      classNamePrefix="version"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
            <FlexGrid.Column sm={8}>
              <Controller
                name="version.default"
                control={control}
                render={({ field: { value, name } }) => {
                  return (
                    <Switch
                      id="defaultVersion"
                      name={name}
                      checked={value}
                      data-checked={value}
                      showText={false}
                      disabled={createMode}
                      label={
                        (
                          <LabelValueWithHint
                            label="Default Version"
                            hint={DATATABLE_DEFAULT_VERSION_TOGGLE_INFO}
                          />
                        ) as any
                      }
                      onChange={e => {
                        setValue(name, e.checked, { shouldDirty: false });
                        updateDatatableVersion(e.checked);
                      }}
                      data-testid="datatableSchemaStep_defaultVersion"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
          </FlexGrid.Row>
          <FlexGrid.Row>
            <FlexGrid.Column sm={12} md={8}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormField
                      id="name"
                      name={name}
                      type="text"
                      placeholder="Enter Data Table Name"
                      label="Data Table Name"
                      disabled={datatableIsPublished}
                      value={value}
                      onChange={onChange}
                      data-testid="datatableSchemaStep_name"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
            <FlexGrid.Column sm={12} md={6}>
              <Controller
                name="code"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormField
                      id="code"
                      name={name}
                      type="text"
                      placeholder="Enter Data Table Code"
                      label={
                        (
                          <LabelValueWithHint
                            label="Data Table Code"
                            hint={DATATABLE_CODE_INFO}
                          />
                        ) as any
                      }
                      disabled={datatableIsPublished}
                      value={value}
                      onChange={(e: any) => onChange(e.value.toUpperCase())}
                      data-testid="datatableSchemaStep_code"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
            <FlexGrid.Column sm={12} md={6}>
              <Controller
                name="backend"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormSelect
                      id="backend"
                      name={name}
                      label="Data Upload Method"
                      value={METHOD_OPTIONS.find(
                        v =>
                          (v.value === "null" && value === null) ||
                          v.value === value
                      )}
                      placeholder="Select Data Upload Method"
                      isClearable={false}
                      disabled={!createMode}
                      options={METHOD_OPTIONS}
                      onChange={({ value: v }: onChangeParam) => {
                        onChange(v.value);
                      }}
                      classNamePrefix="backend"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
          </FlexGrid.Row>
          {backend === "huron" && (
            <FlexGrid.Row>
              <FlexGrid.Column md={12} lg={8}>
                <Controller
                  name="source.host"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    const handleInputChange = (inputValue: string) => {
                      let trimmedValue = inputValue.startsWith("s3://")
                        ? inputValue.slice(5)
                        : inputValue;
                      trimmedValue = trimmedValue.endsWith("/")
                        ? trimmedValue.slice(0, -1)
                        : trimmedValue;
                      onChange(trimmedValue);
                    };
                    return (
                      <FormField
                        id="host"
                        name={name}
                        type="text"
                        placeholder="Enter remote path"
                        label={
                          (
                            <LabelValueWithHint
                              label="Remote Path"
                              hint={DATA_LOCATION_REMOTE_PATH_INFO}
                            />
                          ) as any
                        }
                        value={value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleInputChange(event.target.value);
                        }}
                        data-testid="dataLocationStep_host"
                      />
                    );
                  }}
                />
              </FlexGrid.Column>
              <FlexGrid.Column md={12} lg={8}>
                <Controller
                  name="source.filePattern"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormField
                        id="filePattern"
                        name={name}
                        type="text"
                        placeholder="Enter file pattern"
                        label={
                          (
                            <LabelValueWithHint
                              label="File Pattern"
                              hint={DATA_LOCATION_FILE_PATTERN_INFO}
                            />
                          ) as any
                        }
                        value={value}
                        onChange={onChange}
                        data-testid="dataLocationStep_filePattern"
                      />
                    );
                  }}
                />
              </FlexGrid.Column>
            </FlexGrid.Row>
          )}
          {!datatableIsPublished && (
            <AddColumnRow>
              <div className={styles["column-actions"]}>
                <Button
                  type="button"
                  onClick={addColumn}
                  data-testid="datatableSchemaStep_addColumn"
                >
                  Add Column
                </Button>
                <AutoSchemaPopulateButton
                  onProcessed={onFileProcessed}
                  hasExistingColumns={fields.length > 0}
                />
              </div>

              <DatatatableColumnFilters
                setFilter={setCurrentFilter}
                currentFilter={currentFilter}
                fields={fields}
              />
            </AddColumnRow>
          )}
        </MetadataGrid>

        <FormProvider {...methods}>
          <DatatableSchemaForm
            backend={backend}
            datatableIsPublished={datatableIsPublished}
            fields={fields}
            currentFilter={currentFilter}
            updateColumn={updateColumn}
            tryRemoveColumn={tryRemoveColumn}
          />
        </FormProvider>

        <ConfirmationModal
          isOpen={!!columnReadyForDeletion}
          question="Are you sure you want to remove this existing column?"
          onConfirm={() =>
            removeColumn(
              columnReadyForDeletion as FieldArrayWithId<
                DatatableSchema,
                "columns",
                "id"
              >
            )
          }
          onDismiss={() => setColumnReadyForDeletion(null)}
        />
      </Step>
      <ConfirmationModal
        isOpen={redirectVendorId !== undefined}
        question="Your data table and table schema has been created. Please update your SQL database."
        onConfirm={() => {
          history.push(
            generatePath(`${PATHS.VENDOR_DATATABLES}`, {
              vendorId: redirectVendorId
            })
          );
          setRedirectVendorId(undefined);
        }}
        confirmText="CLOSE"
      />
    </>
  );
};

export default DatatableSchemaStep;
