import { FormFieldWithIcon } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import React, { useState } from "react";

function LazyAdminSearch({
  setSearchParams
}: {
  setSearchParams: (params: Record<string, string | string[]>) => void;
}) {
  const [searchString, setSearchString] = useState("");

  const handleSearchInput = (e: CustomChangeEvent) => {
    const enteredQuery = (e.target as HTMLInputElement).value;
    setSearchString(enteredQuery);
  };
  const handleAction = () => {
    setSearchParams({ search: searchString || [], page: [] });
  };
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAction();
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormFieldWithIcon
        placeholder="Search"
        iconClassName="fa-search"
        addonType="append"
        value={searchString}
        onChange={handleSearchInput}
        onIconClick={handleAction}
      />
    </form>
  );
}

export default LazyAdminSearch;
