import * as yup from "yup";
import { isFinite, isNaN, mapValues, toNumber } from "lodash";
import moment from "moment";

const userProductAccessOption = yup.object({
  label: yup.string().required(),
  type: yup.string().required().oneOf(["User"]),
  value: yup.string().required().email()
});

const userGroupProductAccessOption = yup.object({
  label: yup.string().required(),
  type: yup.string().required().oneOf(["User Group"]),
  value: yup.string().required()
});

const productAccess = yup
  .object({
    users: yup.array(userProductAccessOption),
    userGroups: yup.array(userGroupProductAccessOption)
  })
  .test(
    "users-and-groups-empty",
    "Please assign at least one user to proceed.",
    (value: any) => {
      const { users, userGroups } = value;
      return (
        (users && users.length > 0) || (userGroups && userGroups.length > 0)
      );
    }
  );

export const CONDITION_OPTIONS = [
  "in",
  "nin",
  "eq",
  "gte",
  "gt",
  "lte",
  "lt",
  "lt / gt",
  "lte / gte"
];

const filterObj = yup
  .array()
  .of(
    yup.object({
      key: yup.string().required(),
      type: yup.string().required(),
      operator: yup
        .string()
        .oneOf(CONDITION_OPTIONS)
        .required("Operator is a required field"),
      value: yup.string().when(["type", "operator"], {
        is: (type: string, operator: string) =>
          type !== "Date" || ["in", "nin"].includes(operator),
        then: schema => schema.required("Value is a required field")
      }),
      dateValue: yup.string().when(["type", "operator"], {
        is: (type: string, operator: string) =>
          type === "Date" && !["in", "nin"].includes(operator),
        then: schema =>
          schema.when("numberValue", {
            is: (data: string | null | undefined) => {
              return isFinite(data);
            },
            then: sch => {
              return sch.equals(
                ["", null, undefined],
                "Please empty this field since the entitlement field has been filled."
              );
            },
            otherwise: sch => sch.required("Date is a required field")
          })
      }),
      newDateValue: yup.string().when(["type", "operator"], {
        is: (type: string, operator: string) =>
          type === "Date" && ["lt / gt", "lte / gte"].includes(operator),
        then: schema =>
          schema.when("newNumberValue", {
            is: (data: string | null | undefined) => {
              return isFinite(data);
            },
            then: sch => {
              return sch.equals(
                ["", null, undefined],
                "Please empty this field since the entitlement field has been filled."
              );
            },
            otherwise: sch => sch.required("Date is a required field")
          })
      }),
      numberValue: yup
        .number()
        .transform(originalValue => {
          if (
            originalValue === null ||
            originalValue === undefined ||
            originalValue === ""
          ) {
            return null;
          }
          return isNaN(originalValue) ? null : toNumber(originalValue);
        })
        .nullable()
        .max(0, "Value must be a negative number")
        .when(["type", "operator"], {
          is: (type: string, operator: string) =>
            type === "Date" && !["in", "nin"].includes(operator),
          then: schema =>
            schema.when("dateValue", {
              is: (date: any) => {
                return date?.length !== 0;
              },
              then: sch => {
                return sch.equals(
                  ["", null, undefined],
                  "Please empty this field since the date field has been filled."
                );
              },
              otherwise: sch => sch.required("Date is a required field")
            })
        }),
      newNumberValue: yup
        .number()
        .transform(originalValue => {
          if (
            originalValue === null ||
            originalValue === undefined ||
            originalValue === ""
          ) {
            return null;
          }
          return isNaN(originalValue) ? null : toNumber(originalValue);
        })
        .nullable()
        .max(0, "Value must be a negative number")
        .when(["type", "operator"], {
          is: (type: string, operator: string) =>
            type === "Date" && ["lt / gt", "lte / gte"].includes(operator),
          then: schema =>
            schema.when("newDateValue", {
              is: (date: any) => {
                return date?.length !== 0;
              },
              then: sch => {
                return sch.equals(
                  ["", null, undefined],
                  "Please empty this field since the date field has been filled."
                );
              },
              otherwise: sch => sch.required("Date is a required field")
            })
        })
    })
  )
  .default([]);

const filters = yup.lazy(obj =>
  yup.object(
    mapValues(obj, () => {
      return filterObj;
    })
  )
);

const datatables = yup.object({
  selectedColumns: yup.lazy(obj =>
    yup.object(
      mapValues(obj, (_, versionKey) => {
        return (
          yup
            .array()
            .of(yup.string().required())
            .default([])
            // columns can't contain a block list for a filters added under the same key
            .test(
              "is-filtered",
              "You cannot exclude selected filterable columns",
              function isFiltered(excludeList: string[], ctx: any) {
                const pathKeys = ctx.path.split(".");
                // Path through context to the corresponding filters of the same version as the selected columns
                const contextIndex = 2; // Equivalent to newProducts[0].datatables
                const codeIndex = 2; // Path keys: ['newProducts[0]', 'datatables', 'SHARADAR/SEP', 'selectedColumns', '1'], ie the code index is in [2]
                const correspondingFilters =
                  ctx.from[contextIndex].value[pathKeys[codeIndex]].filters[
                    versionKey
                  ];

                let passesValidation = true;
                correspondingFilters.forEach((filter: { key: string }) => {
                  if (excludeList.includes(filter.key))
                    passesValidation = false;
                });

                return passesValidation;
              }
            )
        );
      })
    )
  ),
  filters
});

const productSchema = yup.object({
  subscriptionType: yup
    .string()
    .required("Subscription type is a required field")
    .oneOf(["autoRenew", "fixedTerm", "trial"]),
  accessStart: yup
    .string()
    .nullable()
    .required("Access start date cannot be blank"),
  accessEnd: yup.string().when("subscriptionType", {
    is: "autoRenew",
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .nullable()
      .required("Access end date cannot be blank")
      .test(
        "is-greater",
        "End date must be after start date",
        function isGreater(value) {
          const { accessStart } = this.parent;

          if (!accessStart) {
            return true;
          }

          return !!(value && moment(value).isAfter(moment(accessStart)));
        }
      )
  }),
  notes: yup.string().nullable(),
  salesRepresentative: yup
    .string()
    .required("Sales representative cannot be blank"),
  businessContacts: yup
    .array()
    .min(1, "Business contacts cannot be blank")
    .of(
      yup.object().shape({
        value: yup.string().email("Must be a valid email address")
      })
    ),
  technicalContacts: yup
    .array()
    .min(1, "Technical contacts cannot be blank")
    .of(
      yup.object().shape({
        value: yup.string().email("Must be a valid email address")
      })
    ),
  welcomeEmailCc: yup.array().of(
    yup.object().shape({
      value: yup.string().email("Must be a valid email address")
    })
  ),
  productAccess,
  datatables: yup.lazy(obj =>
    yup.object(
      mapValues(obj, () => {
        return datatables;
      })
    )
  )
});

export const userFormSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  jobTitle: yup.string(),
  email: yup.string().email().required(),
  asAdmin: yup.boolean().default(false)
});

export const formSchema = yup.object({
  newProductKeys: yup
    .array()
    .of(
      yup.object({
        label: yup.string(),
        value: yup.object({
          id: yup.string(),
          name: yup.string(),
          code: yup.string(),
          productType: yup.string()
        })
      })
    )
    .default([]),
  newProducts: yup.array().of(productSchema).default([]),
  products: yup
    .array()
    .of(
      yup.object({
        name: yup.string(),
        code: yup.string(),
        productType: yup.string()
      })
    )
    .default([]),

  singleProductControl: yup.boolean()
});
