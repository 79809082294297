import React, { useEffect, useMemo } from "react";
import {
  Button,
  FormField,
  FormSelect,
  FormSelectAsync,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import {
  Controller,
  DeepPartial,
  FormProvider,
  UnpackNestedValue,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useMutation } from "jsonapi-react";
import { decamelizeKeys } from "humps";
import { Option } from "@nef/core/lib/components/components/FormBaseSelect";
import { pick } from "lodash";

import extractFormSelectOnChangeValue from "../../utils/nef-utils";

import { LazyAdminTableColumn } from "./lazy-admin";

export default function LazyAdminModal<T, K>({
  isOpen,
  close,
  selection,
  resource,
  columns,
  submissionTransformer
}: {
  isOpen: boolean;
  close: () => void;
  selection?: T & { id?: string };
  resource: string;
  columns: LazyAdminTableColumn<T, K>[];
  submissionTransformer?: (value: any) => any;
}) {
  const editableIds = useMemo(
    () =>
      columns
        .filter(column => column.editable && !column.relationship)
        .map(column => column.id),
    [columns]
  );
  const relationshipDefaultValues = useMemo(() => {
    if (!selection) return {};

    const result: {
      [_: string]: Option[] | Option;
    } = {};
    columns.forEach(column => {
      if (!column.relationship) return;

      const { valueAccess, labelAccess } = column.relationship;
      const relationshipAccess = column.id;
      // @ts-ignore
      const relationshipValue = selection[relationshipAccess];
      if (!relationshipValue) return;

      if (column.editable && column.relationship?.type === "one") {
        result[column.id] = {
          value: relationshipValue[valueAccess],
          label: relationshipValue[labelAccess]
        };
      } else if (column.editable && column.relationship?.type === "many") {
        result[column.id] = relationshipValue.map((v: K) => {
          return {
            value: v[valueAccess],
            label: v[labelAccess]
          };
        });
      }
    });
    return result;
  }, [selection, columns]);

  const schema = useMemo(() => {
    const result: { [_: string]: yup.AnySchema } = {};
    columns.forEach(column => {
      if (column.editable?.schema) {
        result[column.id] = column.editable.schema;
      }
    });
    return yup.object(result);
  }, [columns]);

  const handleClose = () => {
    close();
  };

  const form = useForm<DeepPartial<T>>({
    resolver: yupResolver(schema)
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  useEffect(() => {
    reset({
      ...schema.getDefault(),
      ...pick(selection, editableIds),
      ...relationshipDefaultValues
    } as any);
  }, [selection, reset, editableIds, relationshipDefaultValues]);

  function handleDropdownValueChange(
    onChange: (...event: any[]) => void,
    formSelectOnChangeParam: any
  ) {
    const newValue = extractFormSelectOnChangeValue(formSelectOnChangeParam);
    onChange(newValue);
  }

  function handleMultiDropdownValueChange(
    onChange: (...event: any[]) => void,
    formSelectOnChangeParam: any
  ) {
    const newValue = formSelectOnChangeParam.value;
    onChange(newValue);
  }

  const [create] = useMutation<T>([resource]);

  const [update] = useMutation<T>([resource, selection?.id], {
    method: "put"
  });

  function handleFormSubmit(_formData: UnpackNestedValue<DeepPartial<T>>) {
    const op = selection?.id ? update : create;
    let formData = _formData;
    if (submissionTransformer) {
      formData = submissionTransformer(_formData);
    }
    op(
      decamelizeKeys(formData, {
        separator: "-"
      })
    ).then(({ error: e }) => {
      if (e) {
        toast.error("Form submission error");
        return;
      }
      toast.success(
        `Entry ${selection?.id ? "updated" : "created"} successfully`,
        {
          autoClose: 2000
        }
      );
      close();
    });
  }

  function handleFormError(errors: any) {
    toast.error(
      `Form submission error: ${Object.values(errors)
        .map((error: any) => error.message)
        .join(", ")}`
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      data-testid="lazy_admin_Modal"
      toggle={handleClose}
      closeOnOutsideClick={false}
    >
      <ModalBody>
        <ModalHeader toggle={close}>Entry Detail</ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
              {columns
                .filter(v => v.editable)
                .map(column => {
                  const editConfig = column.editable;
                  if (!editConfig) {
                    return null;
                  }
                  if (editConfig.type === "hidden") {
                    return (
                      <Controller
                        key={column.id}
                        name={column.id}
                        control={control}
                        render={({ field: { name } }) => {
                          return (
                            <input
                              type="hidden"
                              id={name}
                              name={name}
                              defaultValue={editConfig.defaultValue}
                            />
                          );
                        }}
                      />
                    );
                  }
                  if (editConfig.type === "reference") {
                    if (!column.relationship) return null;

                    return (
                      <Controller
                        key={column.id}
                        name={column.id}
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <FormSelectAsync
                              id={name}
                              name={name}
                              label={column.title || editConfig.title}
                              isMulti={editConfig.multiple}
                              value={value}
                              onChange={(formSelectOnChangeParam: any) => {
                                handleMultiDropdownValueChange(
                                  onChange,
                                  formSelectOnChangeParam
                                );
                              }}
                              defaultOptions={editConfig.defaultOptions}
                              loadOptions={editConfig.loadOptions}
                              isSearchable
                              classNamePrefix="lazy_admin_select"
                            />
                          );
                        }}
                      />
                    );
                  }
                  if (editConfig.type === "string") {
                    if (editConfig.options) {
                      const { options } = editConfig;
                      return (
                        <Controller
                          key={column.id}
                          name={column.id}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <FormSelect
                              id={name}
                              name={name}
                              label={column.title}
                              value={
                                options.find((o: any) => o.value === value) ||
                                null
                              }
                              placeholder={column.title}
                              options={options}
                              onChange={(formSelectOnChangeParam: any) => {
                                handleDropdownValueChange(
                                  onChange,
                                  formSelectOnChangeParam
                                );
                              }}
                              classNamePrefix="lazy_admin_select"
                            />
                          )}
                        />
                      );
                    }
                    return (
                      <Controller
                        key={column.id}
                        name={column.id}
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <FormField
                              id={name}
                              name={name}
                              type="text"
                              label={column.title}
                              placeholder={column.title}
                              // TODO
                              value={value as any}
                              onChange={onChange}
                              data-testid={`lazy_admin_${name}`}
                            />
                          );
                        }}
                      />
                    );
                  }
                  throw new Error("unknown type");
                })}
              <Button type="submit" isLoading={isSubmitting}>
                {selection?.id ? "Update" : "Create"}
              </Button>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalBody>
    </Modal>
  );
}
