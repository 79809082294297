import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useQuery } from "jsonapi-react";
import {
  Button,
  EmptyState,
  FontAwesomeIcon,
  Loader,
  Pagination
} from "@nef/core";
import * as yup from "yup";
import { DeepPartial, Path, UnpackNestedValue } from "react-hook-form";

import useSearchParams from "../../hooks/useSearchParams";

import LazyAdminTable from "./lazy-admin-table";
import styles from "./lazy-admin.module.scss";
import LazyAdminSearch from "./lazy-admin-search";
import LazyAdminModal from "./lazy-admin-modal";

const extractPageNumber = (url?: string): number => {
  if (!url) {
    return 1;
  }

  const urlObject = new URL(url);
  const pageNumber = urlObject.searchParams.get("page[number]");

  return pageNumber ? parseInt(pageNumber, 10) : 1;
};

export type LazyAdminTableColumn<T, K> = {
  id: Path<DeepPartial<T>>;
  relationship?: {
    type: string;
    labelAccess: keyof K;
    valueAccess: keyof K;
  };
  title?: string;
  leftAlign?: boolean;
  sortable?: boolean;
  tableValueRenderer?: (_: any) => React.ReactNode;
  editable?: {
    type: string;
    defaultValue?: string;
    schema?: yup.SchemaOf<any>;
    options?: {
      label: string;
      value: string;
    }[];
    title?: string;
    multiple?: boolean;
    defaultOptions?: true;
    loadOptions?: (_: string, callback: any) => void;
  };
  date?: boolean;
};

export default function LazyAdmin<T, K>({
  resource,
  title,
  allowDelete = false,
  filter = {},
  defaults = {},
  tableColumns = [],
  include = [],
  submissionTransformer,
  children
}: PropsWithChildren<{
  resource: string;
  allowDelete?: boolean;
  submissionTransformer?: (
    value: UnpackNestedValue<DeepPartial<T>>
  ) => UnpackNestedValue<DeepPartial<T>>;
  title?: string;
  filter?: Record<string, any>;
  include?: string[];
  defaults?: {
    page?: string;
    sortBy?: string;
    sortOrder?: string;
    searchable?: string;
    pageSize?: number;
  };
  tableColumns: LazyAdminTableColumn<T, K>[];
}>) {
  const [currentSelection, setCurrentSeletion] = useState<T | null>(null);
  const mergedDefault = {
    page: "1",
    sortBy: "name",
    sortOrder: "asc",
    pageSize: 10,
    ...defaults
  };
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, _setSearchParams] = useSearchParams({
    page: mergedDefault.page,
    sortBy: mergedDefault.sortBy,
    sortOrder: mergedDefault.sortOrder
  });
  const setSearchParams = (params: Record<string, string | string[]>) => {
    const searchParamsObject = Object.fromEntries(searchParams);
    _setSearchParams({ ...searchParamsObject, ...params });
  };
  const paramSearch = searchParams.get("search");
  const paramPage = searchParams.get("page") || mergedDefault.page;
  const paramSortBy = searchParams.get("sortBy") || mergedDefault.sortBy;
  const paramSortOrder = searchParams.get("sortOrder") || mergedDefault.sortBy;
  const page = useMemo(() => {
    return parseInt(paramPage, 10);
  }, [paramPage]);

  const sort = useMemo(() => {
    const res = `${paramSortOrder === "asc" ? "" : "-"}${paramSortBy}`;
    if (res.length === 0) {
      return undefined;
    }
    return res;
  }, [paramSortBy, paramSortOrder]);

  const searchFilter = useMemo(() => {
    const result: Record<string, any> = {};
    if (mergedDefault.searchable) {
      result[mergedDefault.searchable] = paramSearch || [];
    }
    return result;
  }, [paramSearch, mergedDefault.searchable]);

  const { data, links, isLoading } = useQuery<T[]>([
    resource,
    {
      filter: {
        ...filter,
        ...searchFilter
      },
      include,
      page: {
        number: page,
        size: mergedDefault.pageSize
      },
      sort
    }
  ]);
  const onPageChange = (num: number) => {
    if (num === 0) {
      setSearchParams({ page: [] });
    } else {
      setSearchParams({ page: `${num + 1}` });
    }
  };
  const nextPage = () => {
    if (page < totalPages) {
      setSearchParams({
        page: `${page + 1}`
      });
    }
  };
  const previousPage = () => {
    if (page === 2) {
      setSearchParams({
        page: []
      });
    } else if (page !== 1) {
      setSearchParams({
        page: `${page - 1}`
      });
    }
  };
  useEffect(() => {
    if (!isLoading) {
      setTotalPages(extractPageNumber(links?.last));
    }
  }, [isLoading]);
  const updateSortParam = (sortBy: string) => {
    let { sortOrder } = mergedDefault;
    if (sortBy === paramSortBy) {
      // toggle if sortBy is already selected
      sortOrder = paramSortOrder === "asc" ? "desc" : "asc";
    }

    if (
      sortBy === mergedDefault.sortBy &&
      sortOrder === mergedDefault.sortOrder
    ) {
      setSearchParams({
        sortBy: [],
        sortOrder: [],
        page: []
      });
      return;
    }
    setSearchParams({
      sortBy,
      sortOrder,
      page: []
    });
  };

  return (
    <section className={styles["content-section"]}>
      <div className={styles.header}>
        <div className={styles["heading-container"]}>
          <h1 className={styles.heading}>{title || resource}</h1>
          {isLoading && (
            <span>
              <Loader />
            </span>
          )}
        </div>
        <div
          className={styles["buttons-container"]}
          data-testid="new-button-container"
        >
          {defaults.searchable && (
            <LazyAdminSearch setSearchParams={setSearchParams} />
          )}
          <Button
            onClick={() => {
              // TODO
              const initialValues: any = {};
              tableColumns
                .filter(v => v.editable)
                .forEach(v => {
                  const { id } = v;
                  initialValues[id] = v.editable?.defaultValue || "";
                });
              // type not correct
              setCurrentSeletion(initialValues as T);
            }}
            data-testid="new-button"
          >
            <FontAwesomeIcon
              iconClassName="fa-plus-circle"
              className={styles["circle-icon"]}
            />
            New
          </Button>
          {children}
        </div>
      </div>
      <LazyAdminModal<T, K>
        isOpen={currentSelection !== null}
        close={() => setCurrentSeletion(null)}
        selection={currentSelection || undefined}
        resource={resource}
        columns={tableColumns}
        submissionTransformer={submissionTransformer}
      />

      {!isLoading && data?.length === 0 ? (
        <EmptyState icon="sloth" title="No Entries Found">
          No Entries Found
        </EmptyState>
      ) : (
        <>
          <LazyAdminTable
            data={data}
            resource={resource}
            tableColumns={tableColumns}
            sortBy={paramSortBy}
            sortOrder={paramSortOrder}
            updateSortParam={updateSortParam}
            onRowClick={v => {
              setCurrentSeletion(v);
            }}
            allowDelete={allowDelete}
          />
          <Pagination
            page={page - 1}
            pages={totalPages}
            pageSize={0}
            pageSizeOptions={[]}
            onPageChange={onPageChange}
            onPageSizeChange={() => null}
            onPageUpdate={() => null}
            nextPage={nextPage}
            previousPage={previousPage}
            showPageSizeDropdown={false}
            showPageDataCount={false}
            totalDataLength={0}
            data-testid="lazy_admin_pagination"
          />
        </>
      )}
    </section>
  );
}
