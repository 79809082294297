import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormRadioCheckboxButton } from "@nef/core";

import { NewOrganization } from "../types";

const InternalCatalogueViewOnlyCheckbox = () => {
  const { control, watch } = useFormContext<NewOrganization>();
  const supportsCatalogueProducts = watch("supportsCatalogueProducts");

  return (
    <Controller
      name="internalCatalogueViewOnly"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <FormRadioCheckboxButton
          id="internalCatalogueViewOnly"
          name={name}
          type="checkbox"
          label="Internal Catalogue View Only"
          value={value}
          onChange={onChange}
          disabled={!supportsCatalogueProducts}
          data-testid="newOrganizationModal_internalCatalogueViewOnly"
        />
      )}
    />
  );
};

export default InternalCatalogueViewOnlyCheckbox;
