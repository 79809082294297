import React from "react";
import { Route, Redirect } from "react-router-dom";

import useSessionAuth from "../../hooks/useDefaultAuth";
import { PATHS } from "../../routes";
import RedirectUnauthenticatedUserModal from "../modals/RedirectUnauthenticatedUserModal";

import NdlLoginRedirect from "./NdlLoginRedirect";

const DefaultRedirect = () => {
  const {
    authenticated,
    isManagerAdmin,
    isPublisherUser,
    isOrganizationUser,
    isAuthenticating,
    isSFTPAdmin,
    isSFTPProductAdmin
  } = useSessionAuth();

  return (
    <Route
      render={() => {
        if (isAuthenticating) {
          return (
            <div data-testid="defaultRedirect_authenticating">
              Authenticating...
            </div>
          );
        }

        if (!authenticated()) {
          return <NdlLoginRedirect />;
        }

        if (isOrganizationUser()) {
          return <Redirect to={PATHS.ORGANIZATIONS} />;
        }

        if (
          isManagerAdmin() ||
          isPublisherUser() ||
          isSFTPAdmin() ||
          isSFTPProductAdmin()
        ) {
          return <Redirect to={PATHS.PUBLISHERS} />;
        }

        return <RedirectUnauthenticatedUserModal />;
      }}
    />
  );
};

export default DefaultRedirect;
