import React from "react";
import ReactDOM from "react-dom";

import ApplicationProvider from "./components/ApplicationProvider";
import "./styles/index.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import * as yup from "./config/yup_defaults";

ReactDOM.render(
  <React.StrictMode>
    <ApplicationProvider>
      <App />
    </ApplicationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// XXX
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
yup.configure();
