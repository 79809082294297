const METHOD_OPTIONS = [
  {
    value: "huron",
    label: "Data Lake Warehouse"
  },
  {
    value: "postgres",
    label: "Postgres Warehouse"
  },
  {
    value: "s3",
    label: "Data Lake (IceBerg)"
  }
];
// eslint-disable-next-line import/prefer-default-export
export { METHOD_OPTIONS };
