import React from "react";
import * as yup from "yup";

import { SftpRemoteLocation } from "../../api/types";
import LazyAdmin from "../../components/lazy-admin/lazy-admin";

export default function RemoteLocationPage() {
  return (
    <>
      <LazyAdmin<SftpRemoteLocation, void>
        resource="sftp-remote-locations"
        title="Remote Locations"
        defaults={{
          sortBy: "updatedAt",
          sortOrder: "desc",
          searchable: "value",
          pageSize: 5
        }}
        allowDelete
        tableColumns={[
          {
            id: "storageType",
            title: "Storage Type",
            sortable: true,
            editable: {
              type: "string",
              defaultValue: "",
              options: [
                { value: "s3", label: "S3" },
                { value: "nfs", label: "NFS" }
              ],
              schema: yup
                .string()
                .required("Storage Type is required")
                .oneOf(["s3", "nfs"])
                .default("")
            }
          },
          {
            id: "value",
            title: "Value",
            sortable: true,
            editable: {
              type: "string",
              defaultValue: "",
              schema: yup.string().required("Value is required").default("")
            }
          },
          {
            id: "createdAt",
            title: "Created At",
            sortable: true,
            date: true
          },
          {
            id: "updatedAt",
            title: "Updated At",
            sortable: true,
            date: true
          }
        ]}
      />
    </>
  );
}
