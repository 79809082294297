import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ProtectedRoute } from "./components/authentication";
import styles from "./routes.module.scss";
import FileManagerPage from "./pages/file-manager/file-manager";
import VendorsPage from "./pages/publisher";
import ProductPage from "./pages/product";
import EditVendorPage from "./pages/publisher/edit-vendor";
import AcceptInvitePage from "./pages/accept-invite/accept-invite";
import EditUserPage from "./pages/users";
import UsersPage from "./pages/user";
import UserGroupsPage from "./pages/user-groups";
import DatatablesPage from "./pages/datatables/datatables";
import NewDatatablePage from "./pages/datatables/new-datatable";
import EditDatatablePage from "./pages/datatables/edit-datatable";
import UploadLogsPage from "./pages/datatables/upload-logs";
import DataPage from "./pages/datatables/data";
import EditCapSurveyPage from "./pages/cap-survey/edit-cap-survey";
import EditProductPage from "./pages/product/edit-product";
import LicencePage from "./pages/product/licence";
import OrganizationsPage from "./pages/organizations/index";
import EditOrganizationsPage from "./pages/organizations/edit";
import OrganizationsUsersPage from "./pages/organizations/edit/users/edit";
import DefaultRedirect from "./components/authentication/DefaultRedirect";
import AISkillsDashboardPage from "./pages/ai-skills/ai-skills-dashboard";
import AISkillsDetailPage from "./pages/ai-skills/ai-skills-detail";
import RemoteLocationPage from "./pages/sftp/remote-location";
import FileProductPage from "./pages/sftp/file-product";
import MountPointPage from "./pages/sftp/mount-point";

export const PATHS = Object.freeze({
  PROFILE: "/profile",
  EDIT_USER: "/users/:userId",
  PUBLISHERS: "/publishers",
  PRODUCTS: "/products",
  EDIT_PRODUCT: "/products/:productId/edit",
  USERS: "/users",
  USERGROUPS: "/user-groups",
  EDIT_PUBLISHER: "/publishers/:publisherId",
  ACCEPT_INVITE: "/accept-invite",
  DATATABLES: "/datatables",
  AI_SKILLS: "/ai-skills",
  AI_SKILL_DETAIL: "/ai-skills/:skillId",
  VENDOR_DATATABLES: "/vendor/:vendorId?/datatables",
  ORGANIZATIONS: "/organizations",
  EDIT_ORGANIZATIONS: "/organizations/:organizationId",
  ORGANIZATIONS_USERS: "/organizations/:organizationId/users/:userId",
  EDIT_CAP_SURVEY: "/cap-survey/:productId",
  EDIT_INTAKE: "/intake/:productId",
  NEW_DATATABLE: "/datatables/new",
  DATATABLE_UPLOAD_LOGS:
    "/datatables/:vendorCode/:datatableCode/:versionCode/uploadEvents",
  DATATABLE_DATA: "/datatables/:vendorCode/:datatableCode/:versionCode/data",
  LICENCE: "/products/:productId/licences/:licenceId",
  NEW_CAP_SURVEY: "/cap-survey/new",
  VENDOR_PRODUCTS: "/vendor/:vendorId?/product",
  EDIT_DATATABLE: "/datatables/:vendorCode/:datatableCode/:versionCode",
  FILE: "/publishers/:vendorId/file/:path?",
  REMOTE: "/sftp/remote",
  FILE_PRODUCTS: "/sftp/file-product",
  MOUNT_POINTS: "/sftp/mount-points"
});

const Routes = () => {
  return (
    <Switch>
      <>
        <main className={styles.main}>
          <Switch>
            <Route
              exact
              path={PATHS.ACCEPT_INVITE}
              component={AcceptInvitePage}
            />
            <ProtectedRoute
              exact
              path={PATHS.PUBLISHERS}
              component={VendorsPage}
            />
            <ProtectedRoute
              path={PATHS.NEW_DATATABLE}
              component={NewDatatablePage}
            />
            <ProtectedRoute
              path={PATHS.EDIT_DATATABLE}
              component={EditDatatablePage}
            />
            <ProtectedRoute path={PATHS.DATATABLE_DATA} component={DataPage} />
            <ProtectedRoute
              path={PATHS.DATATABLE_UPLOAD_LOGS}
              component={UploadLogsPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.PRODUCTS}
              component={ProductPage}
            />
            <ProtectedRoute
              path={PATHS.EDIT_PRODUCT}
              component={EditProductPage}
            />
            <ProtectedRoute exact path={PATHS.USERS} component={UsersPage} />
            <ProtectedRoute
              exact
              path={PATHS.USERGROUPS}
              component={UserGroupsPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.DATATABLES}
              component={DatatablesPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.AI_SKILLS}
              component={AISkillsDashboardPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.AI_SKILL_DETAIL}
              component={AISkillsDetailPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.EDIT_PUBLISHER}
              component={EditVendorPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.PUBLISHERS}
              component={VendorsPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.EDIT_USER}
              component={EditUserPage}
            />
            <ProtectedRoute
              path={PATHS.EDIT_INTAKE}
              component={EditCapSurveyPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.ORGANIZATIONS}
              component={OrganizationsPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.EDIT_ORGANIZATIONS}
              component={EditOrganizationsPage}
            />
            <ProtectedRoute
              exact
              path={PATHS.ORGANIZATIONS_USERS}
              component={OrganizationsUsersPage}
            />
            <Redirect from={PATHS.EDIT_CAP_SURVEY} to={PATHS.EDIT_INTAKE} />
            <ProtectedRoute
              path={PATHS.EDIT_PRODUCT}
              component={EditProductPage}
            />
            <ProtectedRoute path={PATHS.LICENCE} component={LicencePage} />
            <ProtectedRoute path={PATHS.FILE} component={FileManagerPage} />
            <ProtectedRoute
              path={PATHS.REMOTE}
              component={RemoteLocationPage}
            />
            <ProtectedRoute
              path={PATHS.FILE_PRODUCTS}
              component={FileProductPage}
            />
            <ProtectedRoute
              path={PATHS.MOUNT_POINTS}
              component={MountPointPage}
            />
            <DefaultRedirect />
          </Switch>
        </main>
      </>
    </Switch>
  );
};

export default Routes;
