import React from "react";
import * as yup from "yup";
import { useClient } from "jsonapi-react";

import { MountPoint, SftpRemoteLocation } from "../../api/types";
import LazyAdmin from "../../components/lazy-admin/lazy-admin";

export default function MountPointPage() {
  const client = useClient();
  return (
    <>
      <LazyAdmin<MountPoint, SftpRemoteLocation>
        resource="mount-points"
        include={["sftp-remote-location"]}
        title="Mount Points"
        defaults={{
          sortBy: "updatedAt",
          sortOrder: "desc",
          searchable: "value",
          pageSize: 5
        }}
        allowDelete
        submissionTransformer={c => {
          const copy = { ...c };
          if (copy.sftpRemoteLocation) {
            copy.remoteLocationId = copy.sftpRemoteLocation.value;
            delete copy.sftpRemoteLocation;
            return copy;
          }
          return c;
        }}
        tableColumns={[
          {
            id: "path",
            title: "Path",
            sortable: true,
            editable: {
              type: "string",
              defaultValue: "",
              schema: yup.string().required("Name is required").default("")
            }
          },
          {
            id: "target",
            title: "Target",
            sortable: true,
            editable: {
              type: "string",
              defaultValue: "",
              schema: yup.string().required("Target is required").default("")
            }
          },
          {
            id: "sftpRemoteLocation",
            relationship: {
              type: "one",
              labelAccess: "value",
              valueAccess: "id"
            },
            editable: {
              type: "reference",
              title: "Remote Location",
              defaultOptions: true,
              loadOptions: (inputValue: string, callback: any) => {
                client
                  .fetch([
                    "sftp-remote-locations",
                    {
                      filter: {
                        ...(inputValue && { value: inputValue })
                      },
                      sort: "-updatedAt"
                    }
                  ])
                  .then((result: any) => {
                    callback(
                      result.data?.map((v: any) => ({
                        label: `${v.value} ( ${v.storageType} )`,
                        value: v.id
                      })) || []
                    );
                  });
              }
            }
          },
          {
            id: "createdAt",
            title: "Created At",
            sortable: true,
            date: true
          },
          {
            id: "updatedAt",
            title: "Updated At",
            sortable: true,
            date: true
          }
        ]}
      />
    </>
  );
}
