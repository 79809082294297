import { decamelizeKeys } from "humps";

import pick from "../../utils/general-utils";
import {
  DatatableColumn,
  DatatableSchema,
  DefaultFilter,
  DatatableSchemaSource
} from "../types";

const ALLOWED_SOURCE_KEYS: (keyof DatatableSchemaSource)[] = [
  "cronTime",
  "filePattern",
  "host",
  "type"
];

const createDatatableBody = (
  datatable: DatatableSchema,
  isPublishing: boolean,
  previousCode: string | null = null
) => {
  const { filters, primaryKey, defaultColumns, defaultFilters } =
    calculateDatatableKeysAndFilters(datatable);

  return decamelizeKeys(
    {
      name: datatable.name,
      datatable_code: datatable.code,
      vendorCode: datatable.vendorCode,
      columns: normalizeColumns(datatable.columns),
      filters,
      source: sanitizeSource(datatable.source),
      fileFormat:
        datatable.backend === "huron"
          ? { partitions: datatable.partitions }
          : datatable.fileFormat,
      primaryKey,
      defaultColumns,
      defaultFilters,
      previousCode,
      version: {
        code: calculateNewVersion(datatable, isPublishing, previousCode),
        default: true
      },
      backend: datatable.backend
    },
    { separator: "_" }
  );
};

export default createDatatableBody;

export const calculateDatatableKeysAndFilters = (
  datatable: DatatableSchema
) => {
  return datatable.columns.reduce(
    (meta, column) => {
      if (column.isIndex) meta.filters.push(column.name);
      if (column.isPrimaryKey) meta.primaryKey.push(column.name);
      if (column.isSampleColumn) meta.defaultColumns.push(column.name);
      if (column.defaultFilters && column.isIndex) {
        const columnDefaultFilters: DefaultFilter[] = column.defaultFilters.map(
          filter => {
            return {
              key: column.name,
              operator: filter.operator,
              value: filter.value
            };
          }
        );
        meta.defaultFilters.push(...columnDefaultFilters);
      }

      return meta;
    },
    {
      filters: [] as string[],
      primaryKey: [] as string[],
      defaultColumns: [] as string[],
      defaultFilters: [] as DefaultFilter[]
    }
  );
};

const calculateNewVersion = (
  datatable: DatatableSchema,
  isPublishing: boolean,
  previousCode: string | null
) => {
  if (previousCode) return "1";
  if (isPublishing) return "FINAL";

  return (parseInt(datatable.version.code, 10) + 1).toString();
};

const normalizeColumns = (columns: DatatableColumn[]) => {
  return columns.map(c => ({
    name: c.name,
    type:
      c.baseType === "decimal"
        ? `decimal(${c.precision},${c.scale})`
        : c.baseType
  }));
};

type SanitizeSourceType = ((source: null) => null) &
  ((source: DatatableSchemaSource) => DatatableSchemaSource) &
  ((source: DatatableSchemaSource | null) => DatatableSchemaSource | null);

export const sanitizeSource = ((source: DatatableSchemaSource | null) => {
  if (source === null) {
    return source;
  }

  const sanitizedSource = pick(source, ALLOWED_SOURCE_KEYS);

  if (sanitizedSource.cronTime === "") {
    sanitizedSource.cronTime = null;
  }

  if (sanitizedSource.type === "s3") {
    delete sanitizedSource.username;
    delete sanitizedSource.password;
  }

  return sanitizedSource;
}) as SanitizeSourceType;
