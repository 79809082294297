import React, { useCallback } from "react";
import {
  Button,
  FormField,
  FormRadioCheckboxButton,
  FormRadioCheckboxGroup,
  Modal,
  ModalBody,
  ModalHeader
} from "@nef/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  SubmitHandler,
  useForm,
  FormProvider
} from "react-hook-form";
import { useMutation } from "jsonapi-react";
import { decamelizeKeys } from "humps";
import { toast } from "react-toastify";
import { debounce, startCase } from "lodash";

import Toast from "../../../../components/Toast";
import FormFieldLabel from "../../../../components/modals/components/FormFieldLabel";
import manageClient from "../../../../api/manage/client";
import { Organization } from "../../../../api/types";

import styles from "./index.module.scss";
import { NewOrganization } from "./types";
import InternalCatalogueViewOnlyCheckbox from "./components/InternalCatalogueViewOnlyCheckbox";

const newOrganizationSchema = yup.object({
  name: yup
    .string()
    .required("Organization name is a required field")
    .label("Organization Name"),
  distributorId: yup
    .string()
    .required("Distributor ID is a required field")
    .label("Distributor ID"),
  organizationType: yup.string().oneOf(["Academic", "Business"]).required(),
  supportsCatalogueProducts: yup.boolean().label("Supports Catalogue Products"),
  internalCatalogueViewOnly: yup
    .boolean()
    .label("Internal Catalogue View Only"),
  adminEmail: yup.string().email().nullable().label("Admin Account")
});

interface Props {
  close: (organization?: Organization) => void;
}

const NewOrganizationModal = ({ close }: Props) => {
  const form = useForm<NewOrganization>({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(newOrganizationSchema)
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isDirty, isValid }
  } = form;

  const [createOrganization] = useMutation<Organization>("organizations", {
    client: manageClient
  });

  const [validateData] = useMutation(["organizations", "validate"], {
    client: manageClient
  });

  const onSubmit: SubmitHandler<NewOrganization> = useCallback(async data => {
    const transformedData = {
      ...data,
      internalCatalogueViewOnly: data.supportsCatalogueProducts
        ? data.internalCatalogueViewOnly
        : false
    };
    const normalizedRequest: any = decamelizeKeys(transformedData, {
      separator: "-"
    });

    const response = await createOrganization({
      ...normalizedRequest
    });

    const { error, errors, data: responseData } = response;

    if (error || errors) {
      const responseErrors = error ? [error] : errors;

      if (!responseErrors) return;

      toast(
        <Toast
          type="error"
          title="There was an error creating the organization"
          details={[]}
        />
      );

      return;
    }

    toast(
      <Toast
        type="success"
        title="Organization created successfully"
        details={[]}
      />
    );

    close(responseData);
  }, []);

  const validateDistributorIdUniqueness = async (
    field: "distributorId",
    value: string
  ) => {
    try {
      newOrganizationSchema.validateSyncAt(field, { [field]: value });
    } catch (err) {
      return;
    }

    const { errors } = await validateData({ "distributor-id": value });

    const fieldHasBeenTaken = errors?.some(
      error =>
        error.title === "has already been taken" &&
        error.source.pointer.includes("distributor-id")
    );

    if (fieldHasBeenTaken) {
      setError(field, {
        type: "custom",
        message: `${startCase(field)} has already been taken`
      });
    }
  };

  const debounceValidateDistributorIdUniqueness = useCallback(
    debounce(validateDistributorIdUniqueness, 300),
    []
  );

  return (
    <FormProvider {...form}>
      <Modal
        isOpen
        data-testid="newOrganizationModal"
        toggle={() => close()}
        closeOnOutsideClick={false}
      >
        <ModalHeader toggle={() => close()} className={styles["modal-header"]}>
          New Organization
        </ModalHeader>
        <ModalBody>
          <div className={styles["remove-margin"]}>
            <FormFieldLabel
              title="Organization Name"
              tooltip="Please submit an organization name"
              optional={false}
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <FormField
                  id="name"
                  name={name}
                  type="text"
                  value={value}
                  onChange={onChange}
                  data-testid="newOrganizationModal_name"
                  invalid={!!formErrors.name}
                  feedback={formErrors?.name?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <FormFieldLabel
            title="Distributor ID"
            tooltip="Please submit a unique distributor ID"
            optional={false}
          />
          <Controller
            name="distributorId"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FormField
                id="distributorId"
                name={name}
                type="text"
                value={value}
                onChange={event => {
                  debounceValidateDistributorIdUniqueness(
                    "distributorId",
                    event.value?.toString() || ""
                  );
                  onChange(event);
                }}
                data-testid="newOrganizationModal_distributorId"
                invalid={!!formErrors.distributorId}
                feedback={formErrors?.distributorId?.message}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            name="organizationType"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormRadioCheckboxGroup
                id="organizationType"
                name="type"
                type="radio"
                inline
                value={value?.toString()}
                onChange={e => onChange(e.value)}
                data-testid="newOrganizationModal_type"
              >
                <FormRadioCheckboxButton
                  id="academic"
                  value="Academic"
                  type="radio"
                  label="Academic"
                  data-testid="newOrganizationModal_academic"
                  style={{ pointerEvents: "unset" }}
                />
                <FormRadioCheckboxButton
                  id="business"
                  value="Business"
                  type="radio"
                  label="Business"
                  data-testid="newOrganizationModal_business"
                  style={{ pointerEvents: "unset" }}
                />
              </FormRadioCheckboxGroup>
            )}
          />
          <FormFieldLabel
            title="Admin Account"
            tooltip="Add an email address to be used for administrator account"
            optional
          />
          <Controller
            name="adminEmail"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FormField
                id="adminEmail"
                name={name}
                type="text"
                value={value}
                onChange={onChange}
                data-testid="newOrganizationModal_adminEmail"
                invalid={!!formErrors.adminEmail}
                feedback={formErrors?.adminEmail?.message}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            name="supportsCatalogueProducts"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormRadioCheckboxButton
                id="supportsCatalogueProducts"
                name={name}
                type="checkbox"
                label="Supports Catalogue Products"
                value={value}
                onChange={onChange}
                data-testid="newOrganizationModal_supportsCatalogueProducts"
              />
            )}
          />
          <InternalCatalogueViewOnlyCheckbox />
          <div className={styles.buttons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={
                !isDirty || !isValid || Object.keys(formErrors).length !== 0
              }
              data-testid="newOrgModal_submit"
            >
              Submit
            </Button>
            <Button
              color="light"
              outline
              onClick={() => close()}
              data-testid="newOrgModal_cancel"
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </FormProvider>
  );
};

export default NewOrganizationModal;
