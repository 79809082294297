import React, { useMemo, useState } from "react";
import {
  AccessorFn,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { formatDistance } from "date-fns";
import { useMutation } from "jsonapi-react";
import { toast } from "react-toastify";
import { Button, ButtonGroup, FontAwesomeIcon } from "@nef/core";

import { ConfirmationModal } from "../modals/ConfirmationModal";

import styles from "./lazy-admin-table.module.scss";
import { LazyAdminTableColumn } from "./lazy-admin";

interface LazyAdminTableProps<T, K> {
  data?: T[];
  tableColumns: LazyAdminTableColumn<T, K>[];
  sortBy?: string;
  sortOrder?: string;
  updateSortParam: (sortBy: string) => void;
  onRowClick?: (row: T) => void;
  allowDelete?: boolean;
  resource: string;
}

function LazyAdminTable<T, K>({
  data,
  tableColumns,
  sortBy,
  sortOrder,
  updateSortParam,
  onRowClick,
  allowDelete,
  resource
}: LazyAdminTableProps<T, K>) {
  const columnHelper = useMemo(() => createColumnHelper<T>(), []);
  const icon = sortOrder === "asc" ? "fa-caret-up" : "fa-caret-down";
  const [currentSelectionId, setCurrentSelectionId] = useState<string>();
  const [deleteResource] = useMutation<T>([resource, currentSelectionId], {
    method: "delete"
  });
  const columns = tableColumns
    .filter(v => v.title)
    .map(c => {
      const access = c.id as unknown as AccessorFn<T>;
      return columnHelper.accessor(access, {
        id: c.id,
        cell: (info: any) => {
          let value = info.getValue();
          if (c.tableValueRenderer) {
            value = c.tableValueRenderer(value);
          }
          return (
            <div>
              {c.date
                ? `${formatDistance(new Date(value), new Date())} ago`
                : value}
            </div>
          );
        },

        header: () => {
          return (
            <div
              className={sortBy === c.id ? styles["sorter-active"] : ""}
              role="button"
              onClick={() => updateSortParam(c.id)}
              onKeyPress={() => updateSortParam(c.id)}
              tabIndex={-1}
            >
              {c.title}
              {sortBy === c.id && (
                <FontAwesomeIcon
                  iconClassName={icon}
                  className={styles["caret-icon"]}
                />
              )}
            </div>
          );
        }
      });
    })
    .concat([
      columnHelper.display({
        id: "actions",
        cell: v => {
          return (
            <ButtonGroup>
              {allowDelete && (
                <Button
                  outline
                  color="danger"
                  onClick={event => {
                    event.stopPropagation();
                    const rowData = v.row.original as T & { id: string };
                    setCurrentSelectionId(rowData.id);
                  }}
                >
                  <FontAwesomeIcon
                    iconClassName="fa-trash"
                    className={styles["delete-icon"]}
                  />
                  <span className={styles["button-text"]}>Delete</span>
                </Button>
              )}
              <Button>
                <FontAwesomeIcon
                  iconClassName="fa-eye"
                  className={styles["view-icon"]}
                />
                <span className={styles["button-text"]}>View</span>
              </Button>
            </ButtonGroup>
          );
        }
      })
    ]);

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <section data-testid="lazy-admin-record">
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={`${
                    header.column.getIsSorted()
                      ? `${styles.selected} ${styles.label}`
                      : styles.label
                  }`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-testid="lazy-admin-table-body">
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              data-testid={`lazy-admin-table-row-${row.id}`}
              onClick={() => onRowClick?.(row.original)}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={
                    cell.column.id === "name"
                      ? `${styles.values} ${styles["name-cell"]}`
                      : styles.values
                  }
                  data-testid={`lazy-admin-table-${cell.column.id}-${row.id}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmationModal
        isOpen={!!currentSelectionId}
        onDismiss={() => setCurrentSelectionId(undefined)}
        onConfirm={() => {
          try {
            deleteResource(currentSelectionId);
            setCurrentSelectionId(undefined);
            toast.success("Record deleted successfully");
          } catch (e) {
            toast.error(
              `Error deleting record: ${e instanceof Error && e.message}`
            );
          }
        }}
        danger
        question="Are you sure you want to delete this record?"
      />
    </section>
  );
}

export default LazyAdminTable;
